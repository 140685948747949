import React from 'react'
import feature from '../modules/feature';
import hero from '../modules/hero';
import imageText from '../modules/imageText';
import logoCloud from '../modules/logoCloud';
import multicolumn from '../modules/multicolumn';
import action from '../modules/action';
import featuredWork from '../modules/featuredWork';
import blogList from '../modules/blogList';
import workList from '../modules/workList';
import pageHeader from '../modules/pageHeader';
import services from '../modules/services';
import missionStatement from '../modules/missionStatement';
import team from '../modules/team';
import list from '../modules/list';
import videoImageOverlay from '../modules/videoImageOverlay';
import headingText from '../modules/headingText';
import marketingGrid from '../modules/marketingGrid';
import threeGrid from '../modules/threeGrid';
import campaignServices from '../modules/campaignServices';
import formText from '../modules/formText';
import featuredVideo from '../modules/featuredVideo';
import careersList from '../modules/careersList';

const PageBuilder = (props) => {
  const { type, pageBuilder, _rawPageBuilder } = props;
  const Components = {
    hero: hero,
    feature: feature,
    imageText: imageText,
    logoCloud: logoCloud,
    multicolumn: multicolumn,
    action: action,
    featuredWork: featuredWork,
    blogList: blogList,
    workList: workList,
    pageHeader: pageHeader,
    services: services,
    missionStatement: missionStatement,
    team: team,
    list: list,
    videoImageOverlay: videoImageOverlay,
    headingText: headingText,
    marketingGrid: marketingGrid,
    threeGrid: threeGrid,
    campaignServices: campaignServices,
    formText: formText,
    featuredVideo: featuredVideo,
    careersList: careersList,
  }
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index]
      })
    }
  })
}

export default PageBuilder
