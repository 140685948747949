import React, { useEffect, useState } from "react"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { FiArrowRight, FiArrowDown } from "react-icons/fi"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./list.scss"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 1
    },
  },
}
const animImage = {
  initial: {
    scale: 1.1
  },
  visible: {
    scale: 1,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      ease: [0, 0.55, 0.45, 1],
      staggerChildren: 0.1,
      duration: 1
    },
  },
}
const animItem = {
  initial: {
    opacity: 0,
    y: 30
  },
  visible: {
    opacity: 1,
    y:0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 1.2,
      ease: [0, 0.55, 0.45, 1],
    },
  },
}

const accordion = {
  hidden: {
    transition: {
      duration: 0.6,
      staggerChildren: 0.3,
      staggerDirection: 1,
      // delay: 0.3,
      ease: [0, 0.55, 0.45, 1],
      // when: "beforeChildren",
    },
  },
  visible: {
    transition: {
      duration: 0.6,
      staggerChildren: 0.3,
      staggerDirection: 1,
      // delay: 0.3,
      ease: [0, 0.55, 0.45, 1],
      // when: "beforeChildren",
    },
  },
}

const list = ({ block }) => {
  const { heading, text, image, listItem, bgColor, color, buttonLabel, buttonLink } = block

  const [isOpen, setIsOpen] = useState(false)
  const accordionHandler = index => {
    if (isOpen === index) {
      return setIsOpen(null)
    }
    setIsOpen(index)
  }

  const controls = useAnimation()
  const [ref, inView] = useInView({
    // threshold: .15,
    rootMargin: "-0% 0% -50% 0%",
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className={image ? "relative overflow" : "section relative overflow-hidden"}
      style={{backgroundColor: bgColor.value, color: color.value}}
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
    >
    
      <div className={image ? "" : "container"}>
        <div className="grid lg:grid-cols-2 items-center">
          <div className=" relative overflow-hidden h-full">
            {image && (
              <motion.div variants={animImage} className="h-[70vh] lg:h-[90vh]"> 
                <GatsbyImage
                image={image.asset.gatsbyImageData}
                alt={image.asset.altText}
                className="h-full after:content-[''] after:bg-black-2/20 after:absolute after:inset-0 after:z-10"
              />
             </motion.div>
            )}
            
            {/* <div className={image ? "absolute inset-0 flex items-center justify-center text-white z-20" : "w-[560px] max-w-full"}>
              <div className="p-8">
                <motion.h2 variants={animItem} className="font-serif italic text-4xl">{heading}</motion.h2>
                <motion.p variants={animItem} className="text-xl">{text}</motion.p>
              </div>
            </div> */}
          
          </div>

          <div className="p-8">
            <div className="">
              <motion.h2 variants={animItem} className="font-bold uppercase text-3xl  lg:text-4xl mb-4">{heading}</motion.h2>
              <motion.p variants={animItem} className="text-xl">{text}</motion.p>

              {buttonLabel && (
                <motion.div variants={animItem} className="mt-6">
                <Link className="btn-primary" to={buttonLink.slug.current}>
                  {buttonLabel}
                </Link>
              </motion.div>
              )}
              
            </div>
             
            <div className="divide-y divide-primary-3  mt-4">
              {listItem.map((item, index) => (
                <div className="py-5 flex flex-col">
                  <motion.button
                    onClick={() => accordionHandler(index)}
                    className="flex gap-8 items-center text-left"
                    variants={animItem}
                  >
                    <h3 className="h2">{item.heading}</h3>
                    {isOpen === index ? <FiArrowDown /> : <FiArrowRight />}
                  </motion.button>

                  <AnimatePresence>
                    {isOpen === index && (
                      <motion.div
                        variants={accordion}
                        animate="animate"
                        initial="hidden"
                      >
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{
                            height: "auto",
                            transition: {
                              duration: 0.6,
                              ease: [0.38, 0.005, 0.215, 1],
                            },
                          }}
                          exit={{
                            height: 0,
                            transition: {
                              duration: 0.6,
                              ease: [0.38, 0.005, 0.215, 1],
                              delay: 0,
                            },
                          }}
                        >
                          <motion.p
                            className="leading-normal py-4 text-xl"
                            initial={{ opacity: 0, y: 15 }}
                            animate={{
                              opacity: 1,
                              y: 0,
                              transition: {
                                duration: 0.6,
                                ease: [0.38, 0.005, 0.215, 1],
                                delay: 0.15,
                              },
                            }}
                            exit={{
                              y: 15,
                              opacity: 0,
                              transition: {
                                duration: 0.3,
                                ease: [0.38, 0.005, 0.215, 1],
                              },
                            }}
                          >
                            {item.description}
                          </motion.p>
                        </motion.div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  {/* <div>
                <p>{item.description}</p>
              </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default list
