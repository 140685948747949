import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const sectionAnim = {
  // initial: {
  //   x: 100,
  // },
  enter: {
    // x: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.1,
      // duration: 5
    },
  },
}
const sectionItem = {
  initial: {
    opacity: 0,
    y: 30
  },
  enter: {
    opacity: 1,
    y:0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 1,
      ease: [0, 0.55, 0.45, 1],
    },
  },
}
const threeGrid = ({ block }) => {
  const { heading, text, threeGridItem, bgColor, color } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    // threshold: 0.3,
    rootMargin: "-0% 0% -50% 0%",
  })

  useEffect(() => {
    if (inView) {
      controls.start("enter")
    }
  }, [controls, inView])
  return (
    <motion.section
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
      className="section"
      style={{ backgroundColor: bgColor.value, color: color.value }}
    >
      <div className="container grid gap-8">
        <motion.div
          variants={sectionItem}
          className="grid max-w-screen-lg mx-auto lg:text-center"
        >
          {text && <p className="font-serif text-lg lg:text-3xl mb-2">{text}</p>}
          {heading && <h2 className="text-3xl lg:text-4xl font-bold uppercase mb-2">{heading}</h2>}
        </motion.div>
        <div className="grid lg:grid-cols-3 gap-4">
          {threeGridItem.map((item, index) => (
            <motion.div
              variants={sectionItem}
              className="bg-primary-2 rounded-md"
            >
              {item.heading && (
                <h3 className="text-2xl font-bold uppercase italic px-4 py-2">
                  {item.heading}
                </h3>
              )}
              {item.image && (
                <div className="">
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.image.asset.altText}
                    objectFit="cover"
                    className="aspect-w-16 aspect-h-9 w-full h-full object-cover"
                  />
                </div>
              )}
              {item.text && (
                <p className="text-md lg:text-xl px-4 py-4">{item.text}</p>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default threeGrid
