import React from 'react'

const imageText = ({block}) => {
  const {heading,text,image} = block
  return (
    <section className="section" data-scroll-section data-scroll data-scroll-repeat>
      <div>
        <h1>{block.heading}</h1>
        <p>{block.text}</p>
      </div>
    </section>
  )
}

export default imageText
