import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import "./featuredWork.scss"

const sectionAnim = {
  // initial: {
  //   x: 100,
  // },
  enter: {
    // x: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.15,
      // duration: 5
    },
  },
}

const titleItemAnim = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
}

const featuredWork = ({ block }) => {
  const { label, heading, text, selectedWork } = block

  console.log("selectedWOrk", selectedWork)

  const controls = useAnimation()
  const [inView, setInView] = useState(false)
  console.log("inView", inView)

  useEffect(() => {
    scroll.on("call", (value, way, obj) => {
      console.log(obj)
      // console.log(way)
      if (value === "module-featured-work" && way === "enter") {
        // setInView(false);
        setInView(true)
        console.log("EBNTERING")
        controls.start("enter")
        // controls.start((i) => ({
        //   enter: {
        //     // x: 0,
        //     transition: {
        //       when: "beforeChildren",
        //       delayChildren: 0.4,
        //       staggerChildren: 0.15,
        //       // duration: 5
        //     },
        //   },
        // }))
        // controls.start((i) => "enter")
      } else {
        setInView(false)
        console.log("eXITING")
        // controls.start("exit")
      }
    })
  }, [controls])

  return (
    <motion.section
      className="section"
      data-scroll-section
      variants={sectionAnim}
      initial="initial"
      animate={controls}
      data-scroll
      data-scroll-call="module-featured-work"
      data-scroll-offset="0"
      data-scroll-id="module-featured-work"
    >
      <div className="container">
        <div className="section__header mb-16">
          <h2 className="display-2 text-center uppercase">{heading}</h2>
        </div>
        <div className="featured-work__list grid gap-20">
          {selectedWork.map((work, index) => {
            const { title, workSelect } = work

            return (
              <article className="featured-work__article flex gap-8">
                <div className="featured-work__article-content w-4/12">
                  <motion.p
                    className="font-serif italic mb-2 text-2xl"
                    variants={titleItemAnim}
                  >
                    case study
                  </motion.p>
                  <motion.h2
                    className="display-2 uppercase mb-4"
                    variants={titleItemAnim}
                  >
                    {title}
                  </motion.h2>
                  <motion.p className="mb-4" variants={titleItemAnim}>
                    Conscious of our footprint, we make sure that our raw
                    materials as well as our packaging use limited resources. By
                    using fair trade wood, recyclable packaging and planting
                    trees to regenerate our forest, we strive for a better
                    future.
                  </motion.p>
                  <motion.a
                    href=""
                    className="font-semibold underline underline-offset-4"
                    variants={titleItemAnim}
                  >
                    GET THE DETAILS
                  </motion.a>
                </div>

                <div className="aspect-w-16 aspect-h-9">
                <GatsbyImage
                    image={workSelect.featuredImage.asset.gatsbyImageData}
                    alt={workSelect.featuredImage.asset.altText}
                    className=" w-full object-cover w-8/12"
                  />
                </div>
                

                {/* <motion.video
                  className={"aspect-video w-full object-cover w-8/12"}
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  controls={false}
                  playsInline
                  variants={titleItemAnim}
                >
                  <source src={workSelect.file.asset.url} type="video/mp4" />
                </motion.video> */}
              </article>
            )
          })}
        </div>

        <div className="featured-work__action flex justify-center mt-12">
          <a href="" className="font-semibold underline underline-offset-4">
            See all projects
          </a>
        </div>
      </div>
    </motion.section>
  )
}

export default featuredWork
