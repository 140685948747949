import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"
import { getGatsbyImageData } from "gatsby-source-sanity"

const multicolumn = ({ block }) => {
  const { heading, text, multicolumnItem, galleryItem, color, bgColor } = block

  // let bgColor = "bg-[#FFF]"
  // let bgColor = "bg-[#016380]"
  // let bgColor = "bg-[#162932]"
  // let bgColor = "bg-gradient-to-br from-indigo-200 via-red-200 to-yellow-100"

  return (
    <section
      className={"section multicolumn py-12 "}
      style={{ backgroundColor: bgColor.value, color: color.value }}
      data-scroll-section
      data-scroll
    >
      <div className="container">
        <div className="section__header mb-16 grid justify-center text-center">
          <div className="">
            <h2 className="display-2 uppercase font-black">{heading}</h2>
            <p className="display-p">{text}</p>
          </div>
        </div>

        <div className="max-w-screen-lg mx-auto">
          {multicolumnItem.map((item, index) => (
            <div className="py-12" data-scroll>
              <h3 className="h2 mb-4 pb-6 border-b border-primary-3/20">{item.heading}</h3>
              <div className="grid lg:grid-cols-12 gap-4 py-6" key={item + index}>

                <div className="hidden lg:block col-span-4">
                  {item.image && (
                    <GatsbyImage
                      image={item.image.asset.gatsbyImageData}
                      alt={item.image.asset.altText}
                      objectFit="contain"
                    />
                  )}
                  
                </div>

                <div className="col-span-7">
                  <div className="prose mx-auto prose-invert" style={{ color: color.value }}>
                    <PortableText
                      value={item._rawPortableText}
                      components={{
                        types: {
                          image: ({ value }) => (
                            <GatsbyImage
                              image={getGatsbyImageData(
                                value.asset._ref,
                                { maxWidth: 1024 },
                                sanityConfig
                              )}
                            />
                          ),
                        },
                      }}
                      onMissingComponent={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default multicolumn
