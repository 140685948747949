import React, { useEffect, useState } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const missionStatement = ({ block }) => {
  const { heading, text, image, bgColor, color } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()
  console.log("inView", inView)

  useEffect(() => {
    if (inView) {
      controls.start("enter")
    }
  }, [controls, inView])

  const sectionAnim = {
    // initial: {
    //   x: 100,
    // },
    enter: {
      // x: 0,
      transition: {
        // when: "beforeChildren",
        // delayChildren: 0.4,
        staggerChildren: 0.1,
        ease: [0, 0.55, 0.45, 1]
        // duration: 5
      },
    },
  }

  const titleItemAnim = {
    initial: { y: 20, opacity: 0 },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0, 0.55, 0.45, 1],
        duration: 0.6,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        eease: [0, 0.55, 0.45, 1],
        duration: 0.6,
      },
    },
  }



  return (
    <motion.section
      className="section"
      style={{backgroundColor: bgColor.value, color: color.value}}
      ref={ref}
      animate={controls}
      variants={sectionAnim}
      initial="initial"
    >
      <div className="container">
        <motion.div
          className="grid grid-cols-1 text-center max-w-screen-lg mx-auto"
          // variants={sectionAnim}
          // initial="initial"
          // animate={controls}
          // data-scroll
          // data-scroll-call="module-mission"
          // data-scroll-offset="0"
        >
          <motion.h2
            variants={titleItemAnim}
            className="col-span-1 display-2 uppercase mb-2"
          >
            {block.heading}
          </motion.h2>
          <motion.p variants={titleItemAnim} className="col-span-1 display-p ">
            {block.text}
          </motion.p>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default missionStatement
