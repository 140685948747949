import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const services = ({ block }) => {
  const { heading, text, servicesItem } = block
  let bgColor = "bg-[#FFF]"
  // let bgColor = "bg-[#016380]"
  // let bgColor = "bg-[#162932]"
  // let bgColor = "bg-gradient-to-br from-indigo-200 via-red-200 to-yellow-100"

  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-0% 0% -50% 0%",
  })

  useEffect(() => {
    if (inView) {
      controls.start("enter")
    }
  }, [controls, inView])

  const sectionAnim = {
    // initial: {
    //   x: 100,
    // },
    enter: {
      // x: 0,
      transition: {
        // when: "beforeChildren",
        // delayChildren: 0.4,
        staggerChildren: 0.1,
        // duration: 5
      },
    },
  }

  const titleItemAnim = {
    initial: { y: 20, opacity: 0 },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: 0.6,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: 0.6,
      },
    },
  }

  // useEffect(() => {
  //   scroll.on("call", (value, way, obj) => {
  //     // console.log(obj)
  //     // console.log(way)
  //     if (value === "module-services" && way === "enter") {
  //       // setInView(false);
  //       setInView(true)
  //       console.log("EBNTERING")
  //       controls.start("enter")
  //     } else {
  //       setInView(false)
  //       console.log("eXITING")
  //       // controls.start("exit")
  //     }
  //   })
  // }, [controls])

  return (
    <section
      className={"section multicolumn py-12 bg-primary text-white"}
      data-scroll-section
    >
      <div className="container-md">
        <motion.div
          ref={ref}
          animate={controls}
          variants={sectionAnim}
          initial="initial"
          className="section__header mb-16 grid justify-center text-center"
        >
          <div className="mx-auto">
            {heading && (
              <motion.h2
                variants={titleItemAnim}
                className="text-3xl lg:text-4xl uppercase font-black"
              >
                {heading}
              </motion.h2>
            )}
            {text && (
              <motion.p variants={titleItemAnim} className="display-p">
                {text}
              </motion.p>
            )}
          </div>
        </motion.div>

        <motion.div
          className="grid grid-cols-3 lg:grid-cols-6 gap-4 text-center"
          variants={sectionAnim}
          initial="initial"
          animate={controls}
          data-scroll
          data-scroll-call="module-services"
          data-scroll-offset="0"
        >
          {servicesItem.map((item, index) => (
            <motion.div
              variants={titleItemAnim}
              // className="h-[100px]"
              key={item + index}
            >
              <GatsbyImage
                image={item.image.asset.gatsbyImageData}
                alt={item.image.asset.altText}
                objectFit="contain"
                className="h-[100px]"
              />
              <div>
                <h3 className="h3 mb-4">{item.heading}</h3>
                <p className="lead">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}

export default services
