import React, { useEffect } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player/vimeo"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.15,
      staggerDirection: 1,
      ease: [0, 0.55, 0.45, 1],
      // when: "beforeChildren",
    },
  },
}

const motionItem = {
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 1,
    },
  },
}

const featuredVideo = ({ block }) => {
  const { heading, text, vimeoUrl, link} = block

  console.log("featuredvid", block)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-0% 0% -30% 0%",
  })

  console.log("link", link)
  useEffect(() => {
    if (inView) {
      controls.start("animate")
    }
  }, [controls, inView])

  let renderLink

  if (link.external) {
    renderLink = link.external
  }
  if (link.internal ) {
    renderLink = link.internal.slug.current
  }

  return (
    <motion.section
      variants={motionSection}
      initial="initial"
      ref={ref}
      animate={controls}
      className="featured-video relative section bg-primary-2"
    >
      <div className="container grid gap-8 items-center">
        <div className="featured-video__content text-center">
          <div>
            <motion.h2 variants={motionItem} className="display-2 uppercase mb-2">{heading}</motion.h2>
            <motion.p variants={motionItem} className="lead">{text}</motion.p>
          </div>
        </div>

        <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg col-span-1">
          <ReactPlayer
            url={vimeoUrl}
            light={false}
            // playsinline={true}
            // muted={true}
            // controls={false}
            playing={false}
            controls={true}
            // loop={true}
            width="100%"
            height="100%"
            config={{
              vimeo: {
                playerOptions: {
                  width: "1080",
                  height: "1920"
                }
              }
            }}
          />
        </div>

        <motion.div variants={motionItem} className="text-center">
          <Link to={renderLink} className="btn-primary">
            {link.title}
          </Link>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default featuredVideo
