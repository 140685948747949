import React, { useEffect, useState } from "react"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { PortableText } from "@portabletext/react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 1,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.5,
    },
  },
}

const careersList = ({ block }) => {
  const { heading, text, image, bgColor, color } = block

  const data = useStaticQuery(graphql`
    query careersListQuery {
      allSanityCareers {
        nodes {
          title
          excerpt
          jobType
          jobPay
          jobLocation
          slug {
            current
          }
          id
        }
      }
    }
  `)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    // threshold: 0.3,
    rootMargin: "-0% 0% -30% 0%",
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className="section"
      // style={{ backgroundColor: bgColor.value, color: color.value }}
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
      style={{backgroundColor: bgColor.value, color: color.value}}
    >
      <div className="container">
        {/* <p>{block.text}</p> */}
        <div className="max-w-screen-md mx-auto text-center">
          <motion.h1
            variants={sectionItem}
            className="display-2 mb-4 text-center uppercase"
          >
            {heading}
          </motion.h1>
          <motion.p variants={sectionItem}>{text}</motion.p>
        </div>

        <div className="grid lg:grid-cols-3 gap-5 mt-8">
          {data.allSanityCareers.nodes.map((item, index) => (
            <Link
              className="relative block p-8 overflow-hidden border border-gray-100 rounded-lg bg-white flex flex-col"
              to={item.slug.current}
            >
              <span className="absolute inset-x-0 bottom-0 h-2  bg-gradient-to-r from-green-300 via-blue-500 to-cyan-600"></span>

              <div className="justify-between sm:flex">
                <div>
                  <h5 className="text-xl font-bold text-gray-900">
                    {item.title}
                  </h5>
                  <p className="mt-1 text-xs font-medium text-gray-600">
                    {item.jobLocation}
                  </p>
                </div>
              </div>

              <div className="mt-4 sm:pr-8">
                <p className="text-sm text-gray-500">{item.excerpt}</p>
              </div>

              <div>
              <p className="relative inline-block mt-4 text-md font-bold text-black">
                <span className="absolute inset-x-0 bottom-0 transition-transform transform bg-primary-3/20 h-2/3 group-hover:scale-110"></span>
                <span className="relative">Find out more</span>
              </p>
              </div>
              

              <dl className="flex mt-6 justify-self-end h-full">
                <div className="flex flex-col-reverse">
                  {/* <dt className="text-sm font-medium text-gray-600">
                    Published
                  </dt> */}
                  <dd className="text-xs text-gray-500">{item.jobType}</dd>
                </div>

                <div className="flex flex-col-reverse ml-3 sm:ml-6">
                  {/* <dt className="text-sm font-medium text-gray-600">
                    Reading time
                  </dt> */}
                  <dd className="text-xs text-gray-500">
                    {item.jobPay}
                  </dd>
                </div>
              </dl>
            </Link>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default careersList
