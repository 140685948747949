import {useStaticQuery, graphql} from 'gatsby'

export default function useBlogData() {
  const data = useStaticQuery(graphql`
    query {
      allSanityBlog {
        edges {
          node {
            _type
            _key
            title
            slug {
              current
              _type
              _key
            }
            _createdAt
            _updatedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const blog = data.allSanityBlog.edges.map(({node}) => {
    const { _key, _type, title, slug, _createdAt, _updatedAt, featuredImage } = node
    return {
      _type,
      _key,
      title,
      slug,
      _createdAt,
      _updatedAt,
      featuredImage
    }
  })

  return {
    blog
  }
}