import React, { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { PortableText } from "@portabletext/react"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 1,
    },
  },
}
const sectionItem = {
  initial: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.5,
    },
  },
}

const formText = ({ block }) => {
  const { heading, text, _rawPortableText } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  //form handling
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    budget: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target

    if (e.target.type === "checkbox") {
      setFormData({ ...formData, [name]: e.target.checked ? "YES" : "NO" })
    } 
    
    else {
      setFormData({ ...formData, [name]: value })
    }
  }

  console.log("formData", formData)

  //form validations
  const [errors, setErrors] = useState({})

  const validate = formData => {
    let formErrors = {}
    if (!formData.firstName) {
      formErrors.firstName = "First name required"
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last name required"
    }
    if (!formData.email) {
      formErrors.email = "Email required"
    }
    if (!formData.message) {
      formErrors.message = "Message is required"
    }
    return formErrors
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = e => {
    setErrors(validate(formData))
    setIsSubmitted(true)
    e.preventDefault()
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...formData }),
      })
        .then(() => alert("Success!"))
        .then(() => setIsSubmitted(false))
        .then(() =>
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            companyName: "",
            budget: "",
            message: "",
          })
        )
        .catch(error => alert(error))
    }
  }, [errors, formData, isSubmitted])
  console.log(errors, formData)
  return (
    <motion.section
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
    >
      <div className="">
        <div className="grid lg:grid-cols-2">
          <div className="bg-primary-3 p-6 flex flex-col justify-center">
            <motion.h2
              variants={sectionItem}
              className="display-2 uppercase mb-2"
            >
              {heading}
            </motion.h2>
            <motion.p variants={sectionItem} className="lead">
              {text}
            </motion.p>
            <div className="prose prose-lg prose-invert text-white">
              <PortableText
                value={_rawPortableText}
                onMissingComponent={false}
              />
            </div>
          </div>
          <div className="p-6">
            <motion.form
              variants={sectionItem}
              name="contact-form"
              method="post"
              className="grid grid-cols-1 gap-6 bg-primary lg:p-6 rounded-md"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact-form" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <label className="block">
                  <span>First name</span>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  text-black
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder=""
                    required
                  />
                  {errors.firstName && <p>{errors.firstName}</p>}
                </label>
                <label className="block">
                  <span>Last name</span>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  text-black
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder=""
                    required
                  />
                  {errors.lastName && <p>{errors.lastName}</p>}
                </label>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <label className="block">
                  <span>Phone</span>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  text-black
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="212-210-6360"
                    required
                  />
                  {errors.phone && <p>{errors.phone}</p>}
                </label>
                <label className="block">
                  <span>Email address</span>
                  <input
                    type="email"
                    className="
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-white
                      text-black
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                    "
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="john@example.com"
                    id="email"
                    name="email"
                    required
                  />
                  {errors.email && <p>{errors.email}</p>}
                </label>
              </div>

              <label className="block">
                <span>Company name</span>
                <input
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-white
                    text-black
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  "
                  value={formData.companyName}
                  onChange={handleChange}
                  id="companyName"
                  name="companyName"
                  required
                />
                {errors.companyName && <p>{errors.companyName}</p>}
              </label>

              <h3 className="text-lg font-bold">Services wanted</h3>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="
                          border-gray-300 border-2
                          text-black
                          focus:border-gray-300 focus:ring-black
                        "
                  id="servicesVideoProduction"
                  name="servicesVideoProduction"
                  onChange={handleChange}
                />
                <span className="ml-2">Video production</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="
                          border-gray-300 border-2
                          text-black
                          focus:border-gray-300 focus:ring-black
                        "
                        id="servicesTVAdvertising"
                        name="servicesTVAdvertising"
                        onChange={handleChange}
                />
                <span className="ml-2">TV Advertising</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="
                          border-gray-300 border-2
                          text-black
                          focus:border-gray-300 focus:ring-black
                        "
                        id="servicesBillboardAdvertising"
                        name="servicesBillboardAdvertising"
                        onChange={handleChange}
                />
                <span className="ml-2">Billboard Advertising</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="
                          border-gray-300 border-2
                          text-black
                          focus:border-gray-300 focus:ring-black
                        "
                  id="servicesDigitalMarketing"
                  name="servicesDigitalMarketing"
                  onChange={handleChange}
                />
                <span className="ml-2">Digital Marketing</span>
              </label>

              <label className="block">
                <span>Budget</span>
                <select
                  name="budget"
                  id="budget"
                  className="
                    block
                    w-full
                    mt-1
                    rounded-md
                    border-gray-300
                    text-black
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                  value={formData.budget}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="$5,000 - $14,999">$5,000 - $14,999</option>
                  <option value="$15,000 - $49,999">$15,000 - $49,999</option>
                  <option value="$50K +">$50K +</option>
                </select>
              </label>

              <label className="block">
                <span>Message</span>
                <textarea
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-white
                    text-black
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  "
                  id="message"
                  name="message"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p>{errors.email}</p>}
              </label>

              <p>
                <input
                  type="submit"
                  value="Submit message"
                  className="btn-primary lg:btn-secondary"
                />
              </p>
            </motion.form>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default formText
