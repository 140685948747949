import React, { useEffect, useState } from "react"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { PortableText } from "@portabletext/react"
import ReactPlayer from "react-player/lazy"
import { GatsbyImage } from "gatsby-plugin-image"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 1,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.5,
    },
  },
}

const campaignServices = ({ block }) => {
  const { heading, text, campaignServicesItem } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    // threshold: 0.5,
    rootMargin: "-0% 0% -20% 0%",

  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <section className="section">
      <div className="container max-w-screen-xl mx-auto">
        <motion.div
          className="mb-12"
          ref={ref}
          animate={controls}
          initial="initial"
          variants={sectionAnim}
        >
          <motion.h2
            variants={sectionItem}
            className="display-2 mb-4 text-center uppercase"
          >
            {heading}
          </motion.h2>
          <motion.p variants={sectionItem} className="">
            {text}
          </motion.p>
        </motion.div>

        <div className="grid divide-y divide-primary-3">
          {campaignServicesItem.map((item, index) => {
            const controls = useAnimation()
            const [ref, inView] = useInView({
              rootMargin: "-0% 0% -20% 0%",
            })

            useEffect(() => {
              if (inView) {
                controls.start("visible")
              }
            }, [controls, inView])

            return (
              <motion.div
                ref={ref}
                animate={controls}
                initial="initial"
                variants={sectionAnim}
                className="grid lg:grid-cols-2 gap-8 justify-center items-center py-12"
              >
                <motion.div
                  variants={sectionItem}
                  className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden col-span-1"
                >
                  <div className="w-full h-full flex items-center justify-center">
                    {item.image && (
                       <GatsbyImage
                        // className="h-[350px]"
                        image={item.image.asset.gatsbyImageData}
                        alt={item.image.asset.altText}
                        objectFit="contain"
                      />
                    )}
                    {item.vimeoUrl && (
                      <ReactPlayer
                      url={item.vimeoUrl}
                      className="w-full h-full object-cover"
                      width="100%"
                      height="100%"
                      playsinline={true}
                      muted={true}
                      controls={false}
                      playing={true}
                      loop={true}
                    />
                    )}
                    
                  </div>
                </motion.div>
                <motion.div className="col-span-1" variants={sectionItem}>
                  <h3 className="h1 mb-4">{item.heading}</h3>
                  <div className="prose prose-invert text-white">
                    <PortableText
                      value={item._rawPortableText}
                      onMissingComponent={false}
                    />
                  </div>
                </motion.div>
              </motion.div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default campaignServices
