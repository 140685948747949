import React, { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"

const logoCloud = ({ block }) => {
  const { heading, text, logoList, bgColor, color } = block

  const controls = useAnimation()
  const [inView, setInView] = useState(false)
  console.log("inView", inView)

  const sectionAnim = {
    // initial: {
    //   x: 100,
    // },
    enter: {
      // x: 0,
      transition: {
        when: "beforeChildren",
        delayChildren: 0.4,
        staggerChildren: 0.1,
        // duration: 5
      },
    },
  }

  const titleItemAnim = {
    initial: { y: 20, opacity: 0 },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: 0.6,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: 0.6,
      },
    },
  }

  useEffect(() => {
    scroll.on("call", (value, way, obj) => {
      // console.log(obj)
      // console.log(way)
      if (value === "module-logoCloud" && way === "enter") {
        // setInView(false);
        setInView(true)
        console.log("EBNTERING")
        controls.start("enter")
      } else {
        setInView(false)
        console.log("eXITING")
        // controls.start("exit")
      }
    })
  }, [controls])

  return (
    <motion.section
      className="section bg-light"
      style={{backgroundColor: bgColor.value, color: color.value}}
      variants={sectionAnim}
      initial="initial"
      animate={controls}
      data-scroll-section
      data-scroll
      data-scroll-call="module-logoCloud"
      data-scroll-offset="0"
    >
      <div className="container">
        <div className="mb-10">
          <motion.h2
            variants={titleItemAnim}
            className="display-2 uppercase text-center"
          >
            {heading}
          </motion.h2>
          <motion.p variants={titleItemAnim}>{text}</motion.p>
        </div>

        <div className="grid grid-cols-6 gap-12">
          {logoList.map((logo, index) => (
            <motion.div
              className="flex items-center justify-center"
              variants={titleItemAnim}
            >
              {/* <h3>{logo.title}</h3> */}
              <GatsbyImage
                image={logo.image.asset.gatsbyImageData}
                alt={logo.image.asset.altText}
                className="w-[150px]"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default logoCloud
