import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import ReactPlayer from "react-player/lazy"

const videoImageOverlay = ({ block }) => {
  const { heading, text, image, url, height } = block

  let heroHeight

  if (height == "100") {
    heroHeight = "h-screen"
  } else if (height == "90") {
    heroHeight = "h-[90vh]"
  } else if (height == "80") {
    heroHeight = "h-[80vh]"
  } else if (height == "70") {
    heroHeight = "h-[70vh]"
  } else if (height == "60") {
    heroHeight = "h-[60vh]"
  } else if (height == "50") {
    heroHeight = "h-[50vh]"
  }

  const headerAnim = {
    animate: {
      transition: {
        when: "beforeChildren",
        delayChildren: 0.4,
        staggerChildren: 0.05,
      },
    },
  }

  const item = {
    initial: { y: 20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: 0.6,
      },
    },
  }

  return (
    <motion.section
      variants={headerAnim}
      initial="initial"
      animate="animate"
      className=""
      data-scroll-section
      data-scroll
    >
      <div className="relative after:content-[''] after:absolute after:inset-0 after:bg-stone-900 after:opacity-0">
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <div className="container text-neutral-50 px-6 lg:px-16">
            <div className="header__content">
              <motion.h1
                variants={item}
                className="header__content-title display"
              >
                {heading}
              </motion.h1>
              <motion.p
                variants={item}
                className="header__content-desc display-lead mb-7"
              >
                {text}
              </motion.p>
              {/* <a href="" className="btn-primary">call to action</a> */}
            </div>
          </div>
        </div>
        <div className="">
          {image && (
            <GatsbyImage
              className={heroHeight}
              image={image.asset.gatsbyImageData}
              alt={image.asset.altText}
            />
          )}
          {url && (
            <div className="">
              <div className="aspect-w-16 aspect-h-9 pointer-events-none" >
                <ReactPlayer
                  url={url}
                  controls={false}
                  width="100%"
                  height="100%"
                  playing={true}
                  playsinline={true}
                  volume={0}
                  mute={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.section>
  )
}

export default videoImageOverlay
