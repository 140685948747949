import React, { useEffect, useState } from "react"

const ContactForm = () => {
  //form handling
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  console.log("formData", formData)

  //form validations
  const [errors, setErrors] = useState({})

  const validate = formData => {
    let formErrors = {}
    if (!formData.firstName) {
      formErrors.firstName = "First name required"
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last name required"
    }
    if (!formData.email) {
      formErrors.email = "Email required"
    }
    if (!formData.message) {
      formErrors.message = "Message is required"
    }
    return formErrors
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = e => {
    setErrors(validate(formData))
    setIsSubmitted(true)
    e.preventDefault()
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...formData }),
      })
        .then(() => alert("Success!"))
        .then(() => setIsSubmitted(false))
        .then(() =>
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            companyName: "",
            message: "",
          })
        )
        .catch(error => alert(error))
    }
  }, [errors, formData, isSubmitted])
  console.log(errors, formData)

  return (
    <form
      name="contact-form"
      method="post"
      className="grid grid-cols-1 gap-6 bg-primary p-6 rounded-md"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact-form" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <label className="block">
          <span>First name</span>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
            value={formData.firstName}
            onChange={handleChange}
            placeholder=""
            required
          />
          {errors.firstName && <p>{errors.firstName}</p>}
        </label>
        <label className="block">
          <span>Last name</span>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
            value={formData.lastName}
            onChange={handleChange}
            placeholder=""
            required
          />
          {errors.lastName && <p>{errors.lastName}</p>}
        </label>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <label className="block">
          <span>Phone</span>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
            value={formData.phone}
            onChange={handleChange}
            placeholder="212-210-6360"
            required
          />
          {errors.phone && <p>{errors.phone}</p>}
        </label>
        <label className="block">
          <span>Email address</span>
          <input
            type="email"
            className="
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-white
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                    "
            value={formData.email}
            onChange={handleChange}
            placeholder="john@example.com"
            id="email"
            name="email"
            required
          />
          {errors.email && <p>{errors.email}</p>}
        </label>
      </div>

      <label className="block">
        <span>Company name</span>
        <input
          type="text"
          className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-white
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  "
          value={formData.companyName}
          onChange={handleChange}
          id="companyName"
          name="companyName"
          required
        />
        {errors.companyName && <p>{errors.companyName}</p>}
      </label>

      <label className="block">
        <span>Message</span>
        <textarea
          type="text"
          className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-white
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  "
          id="message"
          name="message"
          rows="3"
          value={formData.message}
          onChange={handleChange}
          required
        />
        {errors.email && <p>{errors.email}</p>}
      </label>

      <p>
        <input type="submit" value="Submit message" className="btn-secondary" />
      </p>
    </form>
  )
}

export default ContactForm
