import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from 'framer-motion';

const pageHeader = ({ block }) => {
  const { heading, text, image, file, height} = block

  let heroHeight;

  if (height == "100") {
    heroHeight = "h-screen"
  } else if ( height == "90") {
    heroHeight = "h-[90vh]"
  } else if ( height == "80") {
    heroHeight = "h-[80vh]"
  } else if ( height == "70") {
    heroHeight = "h-[70vh]"
  } else if ( height == "60") {
    heroHeight = "h-[60vh]"
  } else if ( height == "50") {
    heroHeight = "h-[50vh]"
  }

  const headerAnim = {
    animate: {
      transition: {
        when: "beforeChildren",
        delayChildren: 0.4,
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    initial: { y: 20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: .6,
      }
    },
  };
  const animImage = {
    initial: { scale: 1.1 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        ease: [0, 0.55, 0.45, 1],
        duration: .8,
      }
    },
  };
  const video = {
    initial: { scale: 1.1, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        ease: [0.38, 0.005, 0.215, 1],
        duration: .6,
      }
    },
  };

  return (
    <motion.section variants={headerAnim} initial="initial" animate="animate" className="overflow-hidden">
      <div className={heroHeight + " relative after:content-[''] after:absolute after:inset-0 after:bg-stone-900 after:opacity-30"}>
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <div className="container flex items-center justify-center text-white">
            <div className="header__content">
              {heading && (
                <motion.h1 variants={item} className="page-header uppercase font-bold">
                  {heading}
                </motion.h1>
              )}
             
              {/* <motion.p variants={item} className="header__content-desc display-lead mb-7 display-p">
                {text}
              </motion.p> */}
            </div>
          </div>
        </div>
        <div className="h-full">
          { image && 
          <motion.div variants={animImage} className="h-full">
            <GatsbyImage
              className="h-full w-full"
              image={image.asset.gatsbyImageData}
              alt={image.asset.altText}
            />
          </motion.div>
           
          }
          { file && 
            <motion.video
              variants={video}
              className={"w-full object-cover " + heroHeight}
              autoPlay={true}
              muted={true}
              loop={true}
              controls={false}
              playsInline
            >
              <source src={file.asset.url} type="video/mp4" />
            </motion.video>
          }
        </div>
      </div>
    </motion.section>
  )
}

export default pageHeader
