import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const sectionAnim = {
  // initial: {
  //   x: 100,
  // },
  enter: {
    // x: 0,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 1,
    },
  },
}

const titleItemAnim = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.6,
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.6,
    },
  },
}

const animImage = {
  initial: {
    scale: 1.2
  },
  enter: {
    scale: 1,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 1
    },
  },
}

const action = ({ block }) => {
  const { heading, text, image, height } = block

  let heroHeight

  if (height == "100") {
    heroHeight = "h-screen"
  } else if (height == "90") {
    heroHeight = "h-[90vh]"
  } else if (height == "80") {
    heroHeight = "h-[80vh]"
  } else if (height == "70") {
    heroHeight = "h-[70vh]"
  } else if (height == "60") {
    heroHeight = "h-[60vh]"
  } else if (height == "50") {
    heroHeight = "h-[50vh]"
  }

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      controls.start("enter")
    }
  }, [controls, inView])



  //form handling
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  console.log("formData", formData)

  //form validations
  const [errors, setErrors] = useState({})

  const validate = formData => {
    let formErrors = {}
    if (!formData.firstName) {
      formErrors.firstName = "First name required"
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last name required"
    }
    if (!formData.email) {
      formErrors.email = "Email required"
    }
    if (!formData.message) {
      formErrors.message = "Message is required"
    }
    return formErrors
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = e => {
    setErrors(validate(formData))
    setIsSubmitted(true)
    e.preventDefault()
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...formData }),
      })
        .then(() => alert("Success!"))
        .then(() => setIsSubmitted(false))
        .then(() =>
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            companyName: "",
            message: "",
          })
        )
        .catch(error => alert(error))
    }
  }, [errors, formData, isSubmitted])
  console.log(errors, formData)

  return (
    <motion.section
      className="action relative lg:after:content-[''] lg:after:absolute lg:after:inset-0 lg:after:bg-stone-900 lg:after:opacity-30"
      ref={ref}
      animate={controls}
      variants={sectionAnim}
      initial="initial"
    >
      <div className="overflow-hidden">
        <motion.div variants={animImage} >
          <GatsbyImage
            className={"w-full " + heroHeight}
            image={image.asset.gatsbyImageData}
            alt={image.asset.altText}
          />
        </motion.div>
      </div>
      

      <div className="section__content py-8 lg:py-0 bg-primary lg:bg-transparent lg:absolute inset-0 z-10 flex items-center justify-center text-white">
        <div className="container">
          <div className="grid lg:grid-cols-2 gap-8 items-center">
            <div>
              <motion.h2 variants={titleItemAnim} className="display mb-2">
                {heading}
              </motion.h2>
              <motion.p variants={titleItemAnim} className="text-xl">
                {text}
              </motion.p>
            </div>

            <form
              name="contact-form"
              method="post"
              className="grid grid-cols-1 gap-6 bg-primary lg:p-6 rounded-md"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact-form" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <label className="block">
                  <span>First name</span>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder=""
                    required
                  />
                  {errors.firstName && <p>{errors.firstName}</p>}
                </label>
                <label className="block">
                  <span>Last name</span>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder=""
                    required
                  />
                  {errors.lastName && <p>{errors.lastName}</p>}
                </label>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <label className="block">
                  <span>Phone</span>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-white
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="212-210-6360"
                    required
                  />
                  {errors.phone && <p>{errors.phone}</p>}
                </label>
                <label className="block">
                  <span>Email address</span>
                  <input
                    type="email"
                    className="
                      mt-1
                      block
                      w-full
                      rounded-md
                      bg-white
                      border-transparent
                      focus:border-gray-500 focus:bg-white focus:ring-0
                    "
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="john@example.com"
                    id="email"
                    name="email"
                    required
                  />
                  {errors.email && <p>{errors.email}</p>}
                </label>
              </div>

              <label className="block">
                <span>Company name</span>
                <input
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-white
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  "
                  value={formData.companyName}
                  onChange={handleChange}
                  id="companyName"
                  name="companyName"
                  required
                />
                {errors.companyName && <p>{errors.companyName}</p>}
              </label>

              <label className="block">
                <span>Message</span>
                <textarea
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-white
                    border-transparent
                    focus:border-gray-500 focus:bg-white focus:ring-0
                  "
                  id="message"
                  name="message"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p>{errors.email}</p>}
              </label>

              <p>
                <input
                  type="submit"
                  value="Submit message"
                  className="btn-primary lg:btn-secondary"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default action
