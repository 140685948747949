import React from "react"

const feature = ({ block }) => {
  const { label, heading, text, featureList } = block
  return (
    <section className="section" data-scroll-section data-scroll data-scroll-repeat>
      <div>
        <span>{label}</span>
        <h1>{heading}</h1>
        <p>{text}</p>
      </div>
      <div>
        <div className="flex gap-10">
        {featureList.map((feature, index) => (
          <div key={feature + index}>
            <span>{label}</span>
            <h2>{feature.heading}</h2>
            <p>{feature.text}</p>
          </div>
        ))}
        </div>
      </div>
    </section>
  )
}

export default feature
