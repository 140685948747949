import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/vimeo"

const hero = ({ block }) => {
  const { heading, text, vimeoUrl, vimeoUrlMobile, image, file, height, postIt, postItNumber } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("animate")
    }
  }, [controls, inView])

  let heroHeight

  if (height == "100") {
    heroHeight = "h-screen"
  } else if (height == "90") {
    heroHeight = "h-[90vh]"
  } else if (height == "80") {
    heroHeight = "h-[80vh]"
  } else if (height == "70") {
    heroHeight = "h-[70vh]"
  } else if (height == "60") {
    heroHeight = "h-[60vh]"
  } else if (height == "50") {
    heroHeight = "h-[50vh]"
  }



  return (
    <motion.section
      ref={ref}
      animate={controls}
      variants={headerAnim}
      initial="initial"
      className="relative"
    >
      <div className="relative after:content-[''] after:absolute after:inset-0 after:bg-stone-900 after:opacity-0">
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <div className="container text-neutral-50 px-6 lg:px-16 pt-[100px]">
            <div className="header__content">
              <motion.h1
                variants={item}
                className="header__content-title display"
              >
                {heading}
              </motion.h1>
              <motion.p
                variants={item}
                className="header__content-desc display-lead mb-7"
              >
                {text}
              </motion.p>
              {/* <a href="" className="btn-primary">call to action</a> */}
            </div>
          </div>
        </div>
        <div className="">
     
          <div className="aspect-w-16 aspect-h-9 hidden lg:block">
          <ReactPlayer
              url={vimeoUrl}
              light={false}
              playsinline={true}
              muted={true}
              controls={false}
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              className=""
            />
          </div>
          <div className="aspect-w-4 aspect-h-5 lg:hidden">
          <ReactPlayer
              url={vimeoUrlMobile}
              light={false}
              playsinline={true}
              muted={true}
              controls={false}
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              className=""
            />
          </div>
        </div>
      </div>
      <motion.div variants={postItAnim} className="hero__post-it">
          <p className="mb-2">{postIt}</p>
          <p>{postItNumber}</p>
      </motion.div>
    </motion.section>
  )
}

export default hero

const headerAnim = {
  animate: {
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.25,
    },
  },
}

const item = {
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
}
const postItAnim = {
  initial: { 
    y: 20, 
    opacity: 0,
    rotate: 0,
    // x: "-50%",
  },
  animate: {
    y: 0,
    // x: "-50%",
    opacity: 1,
    rotate: -6,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
}