import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      ease: [0, 0.55, 0.45, 1],
      duration: 1
    },
  },
}
const animItem = {
  initial: {
    opacity: 0,
    y: 20
  },
  enter: {
    opacity: 1,
    y:0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      // staggerChildren: 0.15,
      duration: .8,
      ease: [0, 0.55, 0.45, 1]
    },
  },
}


const teamItem = {
  initial: {
    // y: 20,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: .8,
      ease: [0, 0.55, 0.45, 1]
    },
  },
}
const animImage = {
  initial: {
    scale: 1.2,
  },
  enter: {
    scale: 1,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      // staggerChildren: 0.1,
      duration: 1,
      ease: [0, 0.55, 0.45, 1],
    },
  },
}

const team = ({ block }) => {
  const { heading, text, teamList, bgColor, color } = block

  // let bgColor = "bg-[#016380]"
  // let bgColor = "bg-[#162932]"
  // let bgColor = "bg-gradient-to-br from-indigo-200 via-red-200 to-yellow-100"

  const headerControls = useAnimation()
  const controls = useAnimation()
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.15,
  })
  // const [ref, inView] = useInView({
  //   threshold: 0.5,
  // })

  useEffect(() => {
    if (sectionInView) {
      headerControls.start("visible")
    }
  }, [headerControls, sectionInView])

  // useEffect(() => {
  //   if (inView) {
  //     controls.start("visible")
  //   }
  // }, [controls, inView])

  return (
    <section
      className={"section multicolumn py-12 "}
      style={{ backgroundColor: bgColor.value, color: color.value }}
      ref={sectionRef}
      animate={headerControls}
      initial="initial"
      variants={sectionAnim}
    >
      <div className="container">
        <div className="section__header mb-16 grid justify-center text-center">
          <div className="max-w-full mx-auto">
            <motion.h2 variants={animItem} className="display-2 uppercase font-black">{heading}</motion.h2>
            <motion.p variants={animItem} className="lead">{text}</motion.p>
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
          {teamList.map((item, index) => {
            const controls = useAnimation()
            const [ref, inView] = useInView( {
              threshold: .5
            })
            console.log("inView", inView)
          
            useEffect(() => {
              if (inView) {
                controls.start("enter")
              }
            }, [controls, inView])
            return(
              <motion.div
                className="flex flex-col gap-4 "
                key={item + index}
                ref={ref}
                animate={controls}
                variants={teamItem}
                initial="initial"
              >
                <div className="overflow-hidden rounded-full aspect-w-1 aspect-h-1 z-10">
                  <motion.div variants={animImage} className="">
                    <GatsbyImage
                      image={item.image.asset.gatsbyImageData}
                      alt={item.image.asset.altText}
                      className="w-full h-full object-cover"
                    />
                  </motion.div>
                </div>
                
                
                <div className="text-center">
                  <motion.h3 variants={animItem} className="h3 mb-1">{item.name}</motion.h3>
                  <motion.p variants={animItem} className="lead font-serif italic">{item.position}</motion.p>
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default team
