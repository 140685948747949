import React from "react"
import useBlogData from "../hooks/useBlogData"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const blogList = () => {
  const { blog } = useBlogData()

  console.log("useBlogData", blog)
  return (
    <section data-scroll-section data-scroll data-scroll-repeat>
      <div className="blog-list grid grid-cols-3 gap-4">
        {blog.map((post, index) => (
          <article key={post.slug + "-" + index}>
            <Link to={post.slug.current}>
              <GatsbyImage
                className="h-full"
                image={post.featuredImage.asset.gatsbyImageData}
                alt={post.featuredImage.asset.altText}
              />
              <h2>{post.title}</h2>
            </Link>
          </article>
        ))}
      </div>
    </section>
  )
}

export default blogList
