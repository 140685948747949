import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ContactForm from "../components/contactForm"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const sectionAnim = {
  // initial: {
  //   x: 100,
  // },
  enter: {
    // x: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      duration: 1,
      staggerChildren: 0.1,
      ease: [0, 0.55, 0.45, 1]
      // duration: 5
    },
  },
}
const animImage = {
  initial: {
    scale: 1.2
  },
  enter: {
    scale: 1,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      // staggerChildren: 0.1,
      duration: 1,
      ease: [0, 0.55, 0.45, 1]
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 30
  },
  enter: {
    opacity: 1,
    y:0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      // staggerChildren: 0.15,
      duration: 1,
      ease: [0, 0.55, 0.45, 1]
    },
  },
}

const marketingGrid = ({ block }) => {
  const { marketingGridItem } = block

  return (
    <section className=" bg-primary-2" data-scroll-section data-scroll data-scroll-repeat>
      <div className="">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          {marketingGridItem.map((item, index) => {
            const controls = useAnimation()
            const [ref, inView] = useInView({
              threshold: 0.5,
            })

            useEffect(() => {
              if (inView) {
                controls.start("enter")
              }
            }, [controls, inView])
            return (
              <motion.div
                ref={ref}
                animate={controls}
                initial="initial"
                variants={sectionAnim}
                className={
                  (index + 1) % 4 === 0 || index + 1 === 1
                    ? "lg:col-span-2 bg-primary h-[70vh] relative overflow-hidden"
                    : "relative bg-primary h-[70vh] rounded-lg overflow-hidden"
                }
              >
                <div className="absolute inset-0 bg-black/30 z-10" />
                {item.image && (
                  <motion.div variants={animImage} className="h-full w-full">
                    <GatsbyImage
                      image={item.image.asset.gatsbyImageData}
                      alt={item.image.asset.altText}
                      // className="h-[70vh]"
                      className={
                        (index + 1) % 4 === 0 || index + 1 === 1
                          ? "h-full w-full"
                          : "h-full w-full"
                      }
                    />
                  </motion.div>
                )}
                <div className="content absolute inset-0 z-10 flex flex-col justify-center lg:w-[380px]">
                  <div
                    className={
                      item.addForm
                        ? "p-8 grid lg:grid-cols-2 gap-8 items-center"
                        : "p-8"
                    }
                  >
                    <div>
                      {item.heading && (
                        <motion.h2
                          variants={sectionItem}
                          className="display-2 mb-2"
                        >
                          {item.heading}
                        </motion.h2>
                      )}
                      {item.text && (
                        <motion.p
                          variants={sectionItem}
                          className="text-xl font-serif"
                        >
                          {item.text}
                        </motion.p>
                      )}

                      {item.buttonLabel && (
                        <motion.div variants={sectionItem}>
                          <Link
                            to={item.buttonLink.slug.current}
                            className="btn-primary mt-4"
                          >
                            {item.buttonLabel}
                          </Link>
                        </motion.div>
                      )}
                    </div>
                    {item.addForm && <motion.div variants={sectionItem}><ContactForm /></motion.div>}
                  </div>
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
      
    </section>
  )
}

export default marketingGrid
